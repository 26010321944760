
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonSpinner, IonIcon, modalController, menuController } from "@ionic/vue";
import { ref, computed } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { refresh, menu } from "ionicons/icons";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiMagazzino from "@/services/magazzino";
import MovimentoDetail from "@/components/magazzino/MovimentoDetail.vue";

export default {
    name: "Movimenti",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonSpinner,
        IonIcon,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));
        const userId = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const loading = ref(false);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * Get all automezzi from the server (refresh icon)
         */
        const movimenti = ref([]);
        async function loadMovimenti() {
            loading.value = true;
            try {
                const res = await apiMagazzino.getMovimenti(userId);
                if (res.status === 200 && res.data.status === 0) {
                    movimenti.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei movimenti", "toast_danger");
                }
                //console.log(prenotazioni.value);
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei movimenti", "toast_danger");
            }
            loading.value = false;
        }
        loadMovimenti();

        /**
         * Open movimento detail page
         */
        async function openDetailModal(movimento) {
            const modal = await modalController.create({
                component: MovimentoDetail,
                componentProps: {
                    data: movimento,
                },
            });

            return modal.present();
        }


        const tipoMovimento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //Carico
                    className = "badge_carico";
                } else if (statusId == 2) {
                    //Scarico
                    className = "badge_scarico";
                }
                return className;
            };
        });

        return {
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            //New field
            refresh,
            loading,
            movimenti,
            openDetailModal,
            menu,
            openMenu,
            loadMovimenti,
            tipoMovimento,
        };
    },
};
